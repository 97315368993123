// Variable overrides
$theme-colors: (
  "primary": #981C34,
  "secondary": #fff, 
  "tertiary":  #80ba27, 
  "grey": #B9BBB6, 
  "white": #ffffff
);
.c-sidebar-nav {
    background-color: #981C34;
}
.c-sidebar-nav-title {
    background-color:  #981C34;
    color: #fff;
    font-size: 50px;
    
}
  .c-sidebar .c-sidebar-minimizer .c-sidebar-fixed {
      background-color:#80ba27;
  }
.c-sidebar-nav-divider{
    border-bottom: 0px solid white;
}
.c-sidebar-nav-brand{
    border-right: #80ba27;
}
 
