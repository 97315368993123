// Here you can add other styles
[class^="bg-"]:not(table) {
    color: #0f0d0d;
}
table {
    border-collapse: collapse;
    width: 100%;
    /* Add any other styles you need */
}
.table-nav{
    justify-content: center;
    display:flex;
}
.table-select{
    float:right;
}
// table,th, td {
//   border: 1px solid white;
//   border-collapse: collapse;
// }
input[type="file"] {
    display: none;
}
.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}
table {
    border-spacing: 0px;
    border-collapse: collapse;  /* <--- add this so all the internal <td>s share adjacent borders  */
    border: 1px solid white;  /* <--- so the outside of the <th> don't get missed  */
}
th {
    background-color: rgba(107,114,128,0.07);
    border: 1px solid white;
    border-spacing: 0px;  /* <---- won't really need this if you have border-collapse = collapse */
    border-style: none;   /* <--- add this for no borders in the <th>s  */
}

tr {
    border-bottom: 1px rgba(107,114,128,0.2);
}

button a {
    color: black;
    
}
button {
    padding: 0;
    border: none;
    background: none;
}
input {
    border:none;
    border-radius: 0.375rem;
    // border-color: rgba(209,213,219,0.2);
    border-color: #6b7280;
    border-width: 1px;
}

input[type="date"] {
    border: 1px solid #6b7280;
    border-radius: 0.375rem;
    // border-color: #6b7280;
    // border-width: 1px;
}

input:focus {
    outline: 1px solid rgba(59, 130, 246, 0.5)
  }

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  
.li-crumb {
float: left;
}
  
li a {
    display: inline-block;
    color: black;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}

.flex-container {
    display: flex;
    justify-content: space-between; /* Places the button at the right end */
    align-items: center; /* Vertically centers the content */
}
.inline-block {
    display: inline-block;
}
.overflow-hidden {
    overflow: hidden;
}
.fixed-dialog {
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
}